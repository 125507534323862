import React, { useState, useEffect } from 'react';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import {
  APP_CONFIG,
} from '../utils';

const AnalyticsWrapper = (props) => {

  const {
    children,
    propyCookieValue,
  } = props;

  const [parsedPropyCookieValue, setParsedPropyCookieValue] = useState(JSON.parse(propyCookieValue ? propyCookieValue : '{}'));

  const gtmParams = {
    id: APP_CONFIG.GOOGLE_GTAG_ID
  };

  useEffect(() => {
    let scripts = [];
    let newParsedPropyCookieValue = JSON.parse(propyCookieValue ? propyCookieValue : '{}');
    if (APP_CONFIG.SMARTLOOK_ID && newParsedPropyCookieValue?.services?.analytics?.indexOf('smartlook') > -1) {
        const script = document.createElement('script')
        script.innerHTML = `
            window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
        })(document);
        smartlook('init', '${APP_CONFIG.SMARTLOOK_ID}', { region: 'eu' });
        `
        document.head.appendChild(script)
        scripts.push(script);
        // const script2 = document.createElement('script');
        // script.type = 'text/javascript';
        // script.id = 'hs-script-loader';
        // script.async = true;
        // script.defer = true;
        // script.src = '//js.hs-scripts.com/4204561.js';

        // document.head.appendChild(script);
    }
    if(newParsedPropyCookieValue?.services?.analytics?.indexOf('twitter') > -1) {
      const twitterScript = document.createElement('script')
      twitterScript.innerHTML = `
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      twq('config','o80ss');
            `
      document.head.appendChild(twitterScript);
      scripts.push(twitterScript);
    }
    setParsedPropyCookieValue(newParsedPropyCookieValue);
    return () => {
      // Remove all scripts we've added
      scripts.forEach(script => {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      });
    };
  }, [propyCookieValue])

  if(parsedPropyCookieValue?.services?.analytics?.indexOf('gtm') > -1) {
    return (
      <GTMProvider state={gtmParams}>
        {children}
      </GTMProvider>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}

export default AnalyticsWrapper;
import { createStore } from 'redux'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

// import {
//     IS_LOCALHOST,
//     CookieService,
// } from '../utils';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ["darkMode"],
    stateReconciler: autoMergeLevel2
};

const pReducer = persistReducer(persistConfig, rootReducer);

const storeExport = createStore(
    pReducer,
    // composeWithDevTools(
    //     //applyMiddleware(sagaMiddleware),
    // )
);

// storeExport.subscribe(() => {
//     const state = store.getState();
    
//     // Check if the consent value is true and the page hasn't been reloaded yet
//     if (state.consentToCookies && !localStorage.getItem('cookieConsentHasReloaded')) {
//         // Set the flag to indicate that the page has been reloaded
//         localStorage.setItem('cookieConsentHasReloaded', 'true');
        
//         // Reload the web page
//         setTimeout(() => {
//             window.location.reload();
//         }, 100);
//     }
//     if(state.consentToCookies && localStorage.getItem('cookieConsentHasReloaded')) {
//         const domain = IS_LOCALHOST ? '.localhost' : '.propy.com';
//         CookieService.set('cookieconsent_status', 'dismiss', 365, '/', domain, false, 'None');
//     }
//   });

export const store = storeExport;
export const persistor = persistStore(storeExport);

export default store;
import React from 'react';

export const isBrowser = () => typeof window !== "undefined"

export const useBrowserDetection = () => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted && isBrowser();
};

export default useBrowserDetection;
import React, { useState, useEffect } from 'react';

import usePageTracking from '../hooks/usePageTracking';

const WindowStateManager = (props) => {

  let {
    location,
    propyCookieValue,
  } = props;

  const [parsedPropyCookieValue, setParsedPropyCookieValue] = useState(JSON.parse(propyCookieValue ? propyCookieValue : '{}'));

  useEffect(() => {
    let newParsedPropyCookieValue = JSON.parse(propyCookieValue ? propyCookieValue : '{}');
    setParsedPropyCookieValue(newParsedPropyCookieValue);
  }, [propyCookieValue])

  usePageTracking(location, parsedPropyCookieValue);

  return (
    <></>
  );
}

export default WindowStateManager